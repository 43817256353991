import api from './api';

//
// query parameters
//

// query parameter - omitMobileCheck
// use presence/absence of a window global variable because non-module main.js needs to reference this.

export function disableMobileCheck() {
	window.$rfxn_mobileCheckDisabled = true;
	global.vision();  // update the DOM if necessary
}

export function mobileCheckDisabled() {
	return ("$rfxn_mobileCheckDisabled" in window);
}


//
// cache the results of api.state.get()
//

export var currentState = {};

export var haveState = false;

// fetch now, whether or not we have a cached copy
export async function fetchState() {
	const s = await api.state.get();
	currentState = s.data;
	haveState = true;
	return currentState;
}

// if have cached, return it, otherwise fetch
export async function getState() {
	if (!haveState)
		await fetchState();
	return currentState;
}

// if state.get has not yet been called, issue an asynchronous call and return immediately
export function checkGetStateCalled() {
	if (!haveState)
		getState();
}
