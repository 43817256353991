import { useCallback, useEffect, useState } from "react";
import { Completed } from "../components/Completed";
import { DrillResult } from "../components/DrillResult";
import { drillCompleted } from "./Index";
import { startMinefield } from "../controllers/minefield";

// cookie just needs to be different every time, so that the effect will run once each invocation
const DrillMinefield = ({ cookie, retake }) => {

	const [result, setResult] = useState();
	const [nextStep, setNextStep] = useState();
	const [todo, setTodo] = useState();

	const minefieldCompleted = useCallback((transcript) => {
		document.getElementById('root').ontouchstart = function () { };

		drillCompleted("minefield", retake, transcript.stringify())
			.then(({ nextStep, result, todo }) => {
				setResult(result);
				setNextStep(nextStep);
				setTodo(todo);
			});
	}, [retake]);

	useEffect(() => {
		document.getElementById('root').ontouchstart = function (e) { e.preventDefault(); };

		startMinefield(minefieldCompleted);
	}, [cookie, minefieldCompleted]);

	if (result) {
		return <DrillResult drill="minefield" result={result} />
	}

	if (nextStep) {
		return <Completed current="minefield" next={nextStep} todo={todo} />;
	}

	return (
		<div id="minefield-background">
			<div id="screen"></div>
		</div>
	)
};

export default DrillMinefield;
