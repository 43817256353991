import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import api from '../api';
import { Header } from "../components/Header";
import { Desktop } from "../components/Desktop";
import { IntroVideo } from "../components/IntroVideo";
import { fetchState, getState, haveState, currentState, disableMobileCheck } from '../globalState';
import { useLocation } from 'react-router-dom'

// given state and assessment state, decide what the next step should be
export const calculateNextStep = ({ accountStatus, hasRScore }, { todo }) => {
	if (todo && todo.length > 0) {
		return `/a/drills-${todo[0]}`;
	}

	// XXX - what do we do if there's no to-do and showProgressPage === false ???  can that ever happen?

	if (accountStatus === "anonymousPerson" && !hasRScore) {
		return '/a/drills';
	}

	return '/d/home';
};

// get state and assessment state, then call calculateNextStep()
export const getNextStep = async () => {
	if (!haveState)
		await getState();
	if (currentState.accountStatus === 'noCookie')
		return '/a/drills';
	const assessmentStateResult = await api.assessmentstate.get();
	return calculateNextStep(currentState, assessmentStateResult.data);
};

export const drillCompleted = async (drillName, isRetake, transcript) => {
	if (isRetake) {
		const retakeResult = await api.drill.drillCompleted(drillName, transcript);
		return { result: retakeResult.data.results[0], nextStep: null, todo: [] };
	}

	const result = await api.assessment.drillCompleted(drillName, transcript);

	return {
		result: null,
		nextStep: calculateNextStep({ accountStatus: "", hasRScore: false }, result.data),
		todo: result.data.todo || []
	};
};

export const Index = () => {
	const [state, setState] = useState({});
	const [showVideo, setShowVideo] = useState(false);
	const [tokenError, setTokenError] = useState("");
	const history = useHistory();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const userSessionToken = queryParams.get('userSession');

	if (queryParams.get('omitMobileCheck') === 'true')
		disableMobileCheck();

	// nextScreen is the next page the user will see after the landing page and intro video.
	// its initial value assumes we are displaying the progress page.
	// after we've fetched the user's state, if showProgressPage=false, we'll set nextScreen to the first drill.
	var nextScreen = "/a/drills";

	useEffect(() => {
		console.log(`Index.jsx - history effect`);

		if (document.querySelector('.js-desktop')) {
			document.querySelector('.js-desktop').setAttribute('style', 'height:' + window.innerHeight + 'px');
		}

		const fetchData = async () => {
			const stateResult = await getState();

			if (stateResult.accountStatus !== 'noCookie') {
				const assessmentStateResult = await api.assessmentstate.get();
				const nextStep = calculateNextStep(stateResult, assessmentStateResult.data);
				console.log(`    Index.jsx - history effect - history.push(${nextStep})`);
				history.push(nextStep);
			}
		};

		fetchData();
	}, [history]);

	useEffect(() => {
		console.log(`Index.jsx - init effect`);
		console.log(`    token = ${userSessionToken}`);

		global.heroImgHeight();

		if (userSessionToken !== null) {
			// we were invoked by an organization (B2B) customer with a token they got via POST /org/usersession.
			// we use that token to log in (creating a cookie session), then we immediately start an assessment.
			api.org.userSessionLogin({ userSessionToken: userSessionToken })
				.then(async () => {
					await fetchState();
					const next = await getNextStep();
					history.push(next);
				})
				.catch(err => {
					console.log(`setting tokenError to ${err.response.data}`);
					setTokenError(err.response.data);
				});
		}
	}, []);

	const onGetStartedClick = async (e) => {
		e.preventDefault();
		await api.newuser.post();
		await fetchState();
		if (!currentState.showProgressPage) {
			await api.assessment.startNew();
			const next = await getNextStep();
			nextScreen = next;
		}
		console.log(`Index.jsx/onGetStartedClick - nextScreen = ${nextScreen}`);
		if (currentState.showVideo) {
			setShowVideo(true);
		} else {
			// whether or not to set the user's seenTrainingIntroVideo flag is debatable. for now, we do it.
			await api.state.videoSeen();
			history.push(nextScreen);
		}
	}

	const onVideoEnded = async () => {
		await api.state.videoSeen();
		history.push(nextScreen);
	}

	console.log(`tokenError = ${tokenError}`);
	if (tokenError !== '') {
		return (
			<>
				<Header title="TOKEN LOGIN ERROR" />
				<main className="main">
					<section className="js-section section">
						<div className="wrapper center-content-main-axis">
							<div className="simple-text-msg">
								Token login failed - {tokenError}
							</div>
						</div>
					</section>
					<Desktop />
				</main>
			</>
		);
	}

	if (showVideo) {
		return (
			<main className="main">
				<IntroVideo
					canSkip={state.seenTrainingIntroVideo}
					skipUrl={nextScreen}
					onEnded={onVideoEnded} />
			</main>
		);
	}

	return (
		<>
			<main className="main">
				<div className="index">
					<section className="hero js-hero">
						<div className="hero-img">
							<img src="/images/hero-img.jpg" alt="" />
						</div>
					</section>
					<div className="hero-btns-block js-hero-btns">
						<div className="wrapper">
							<div className="hero-btns">
								<a className="hero-btn btn" href="/video" onClick={onGetStartedClick}>
									let's go
								</a>
							</div>
							<div className="hero-links">
								<p className="hero-links__txt">
									Already have an account?{" "}
									<Link className="hero-link" to="/d/login">
										Sign in
									</Link>{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
				<Desktop />
			</main>
			<footer className="footer footer--wlogo">
				<div className="wrapper">
					<div className="footer-wlogo">
						<div className="header-logo" style={{ justifyContent: 'center' }}>
							<img src="/images/reflexion.png" alt="" />
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
