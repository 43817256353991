import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import download from 'downloadjs';
import api from '../api';
import { Desktop } from "../components/Desktop";
import { Header } from "../components/Header";
import { fetchState, getState, haveState, currentState } from "../globalState";

const formatNumber = (n) => {
	return !n ? '' : n.toFixed(2);
}

const toObject = (cognitions) => {
	return cognitions.reduce((obj, curr) => ({ ...obj, [curr.name]: curr }), {});
}

const CognitionTag = ({ i, active }) => (
	<div className={`cognition-tab cognition-tab-${i} ${active ? 'active' : ''}`}>
		<a className="tab-link" href="#">
			<span className="tab-link-icon">
				<span className="tab-link-icon--default">
					<i className={`svg-image-about-icon-${i}-sm`}> </i>
				</span>
				<span className="tab-link-icon--active">
					<i className={`svg-image-about-icon-${i}-bl`} />
				</span>
			</span>
		</a>
	</div>
);

const CognitionPane = ({ title, value, text, percentile, active }) => (
	<div className={`cognition-pane ${active ? 'active' : ''}`}>
		<div className="cognition-pane__inner">
			<div className="cognition-pane__descr">
				<h3 className="cognition-pane__title">
					{title} <span>{formatNumber(value)}</span>
				</h3>
				<p className="cognition-pane__txt">
					{text}
				</p>
			</div>
			<div className="cognition-pane__chart">
				<div className="chart chart--red">
					<div className="chart-wrapp">
						<div className="chart-inner">
							<div className="chart-field" />
							<div className="chart-gradient" />
							<div className="chart-point js-chart-el">
								<div
									className="chart-point__el"
									style={{ left: `${formatNumber(percentile)}%` }}
								/>
							</div>
							<div className="chart-indicator js-chart-el">
								<div
									className="chart-indicator__el"
									style={{ left: `${formatNumber(percentile)}%` }}
								/>
							</div>
						</div>
					</div>
					<p className="chart-info">
						Better than <span>{formatNumber(percentile)}%</span> of users
					</p>
				</div>
			</div>
		</div>
	</div>
);

export const Home = () => {
	const [chartData, setChartData] = useState(null);
	const [displayName, setDisplayName] = useState("");
	const [goToTraining, setGoToTraining] = useState(false);
	const [loadedState, setLoadedState] = useState(false);
	const history = useHistory();

	const [cognitions, setCognitions] = useState({
		"inhibition": {},
		"reaction time": {},
		"processing speed": {},
		"prioritization": {},
		"mental flexibility": {}
	});

	const processCognitions = (cognitions) => {
		const obj = toObject(cognitions);
		setCognitions(obj);
		setChartData([
			Math.round(obj["reaction time"].value / 10),
			Math.round(obj["inhibition"].value / 10),
			Math.round(obj["prioritization"].value / 10),
			Math.round(obj["processing speed"].value / 10),
			Math.round(obj["mental flexibility"].value / 10)
		])
	}

	useEffect(() => {
		const fetchData = async () => {
			const stateResult = await getState();
			if (currentState.showSpiderGraph) {
				setDisplayName(stateResult.displayName);

				const result = await api.assessmentlatest.get();
				processCognitions(result.data.cognitions);
			}
			setLoadedState(true);
		};
		global.cognitionAnim();
		global.tabs();
		fetchData();
	}, []);

	useEffect(() => {
		if (chartData !== null) {
			global.chart1();
			global.chartSizes();
		}
	}, [chartData])

	const downloadSocialMedia = async (e) => {
		e.preventDefault();

		const { data } = await api.assessmentlatest.getImage();

		download(data, "TrainYourMind.png", "image/png");
	}

	if (!(haveState && currentState.showSpiderGraph)) {
		api.logout.post().then(() => fetchState());

		return (
			<>
				<Header title="Assessment Completed" />
				<main className="main">
					<section className="js-section section">
						<div className="wrapper center-content-main-axis">
							<div className="simple-text-msg">
								Congratulations! You have completed your Reflexion cognitive assessment.
							</div>
						</div>
					</section>
					<Desktop />
				</main>
			</>
		);
	}

	if (goToTraining)
		return (
			<main className="main">
				<section className="drill-video js-section section">
					<div className="drill-video__wrap js-video">
						<video
							id="video-page"
							autoPlay
							muted
							playsInline
							onEnded={() => history.push('/d/training')}
						>
							<source src="/video/see-what-others-cant-see.mp4" type="video/mp4" />
						</video>
					</div>
					<div className="drill-video__btns">
						<a href="/" onClick={e => {
							e.preventDefault();
							history.push('/d/training');
						}}> &lt;&lt; Skip &gt;&gt;</a>
					</div>
				</section>
			</main>);

	return (
		<>
			<Header title="Reflexion Score ™ " />
			<main className="main">
				<section className="cognition js-section section">
					<div className="wrapper">
						<div className="cognition-wrapp js-cognition">
							<div className="cognition-header">
								{loadedState && displayName && <h2 className="cognition-header__title">
									<span>{displayName}</span>
								</h2>}
								<div className="cognition-header__descr">
									Here’s where you stack up.
								</div>
							</div>
							<div className="cognition-tabs">
								<CognitionTag key={1} i={1} active={false} />
								<CognitionTag key={2} i={2} active={false} />
								<CognitionTag key={3} i={3} active={false} />
								<CognitionTag key={4} i={4} active={false} />
								<CognitionTag key={5} i={5} active={true} />
								<div className="cognition-pentagon">
									{chartData && <canvas
										className="chart1"
										data-results={chartData}
									/>}
								</div>
								<div className="cognition-pointer">
									<div className="cognition-pointer-v5">
										<div className="svg-image-pointinghand" />
									</div>
								</div>
							</div>
							<div className="cognition-logo">
								<img src="/images/logo.png" alt="" />
							</div>
							<div className="cognition-cnt">
								<CognitionPane
									title={"Reaction Time"}
									value={cognitions["reaction time"].value}
									percentile={cognitions["reaction time"].percentile}
									text={"How fast your brain and body can react"} />
								<CognitionPane
									title={"Inhibition"}
									value={cognitions["inhibition"].value}
									percentile={cognitions["inhibition"].percentile}
									text={"Stopping yourself from reacting to the wrong things"} />
								<CognitionPane
									title={"Prioritization"}
									value={cognitions["prioritization"].value}
									percentile={cognitions["prioritization"].percentile}
									text={"Doing the most important things first"} />
								<CognitionPane
									title={"Processing Speed"}
									value={cognitions["processing speed"].value}
									percentile={cognitions["processing speed"].percentile}
									text={"How fast your brain processes information"} />
								<CognitionPane
									title={"Mental Flexibility"}
									value={cognitions["mental flexibility"].value}
									percentile={cognitions["mental flexibility"].percentile}
									text={"Responding quickly to different situations"}
									active />
							</div>
						</div>
						<div className="cognition-links">
							<Link className="cognition-link" to="/a/drills">Take another assessment</Link>
							{/* <br /><br />
              <a className="cognition-link" href="/" onClick={downloadSocialMedia}>
                Challenge a friend (download)
              </a> */}
						</div>
					</div>
				</section>
				<Desktop />
			</main>
			<footer className="footer footer--btns">
				<div className="wrapper">
					<div className="footer-btns">
						<Link className="footer-btn btn" to="/d/social-media">
							Challenge a friend <i className="svg-image-share" />
						</Link>
						<button className="footer-btn btn" onClick={() => setGoToTraining(true)}>
							Go to training <i class="svg-image-goto"></i>
						</button>
					</div>
				</div>
			</footer>
		</>
	)
}
