import { useEffect, useState } from "react";
import { Desktop } from "../components/Desktop";
import { DrillIntroVideo } from "../components/DrillIntroVideo";
import DrillMinefield from "./DrillMinefield";
import { checkGetStateCalled, haveState, currentState } from '../globalState';

const DrillMinefieldIntro = ({ retake }) => {
    const [gameStarted, setGameStarted] = useState(false);
    const [videoPlayed, setVideoPlayed] = useState(false);

    useEffect(() => {
        checkGetStateCalled();
        console.log(`DrillMinefieldIntro.jsx`);
    }, []);

    if ((!videoPlayed) && !(haveState && currentState.showVideos)) {
        // if state hasn't been received yet, err on the side of NOT playing the video
        setVideoPlayed(true);
    }

    if (!videoPlayed)
        return <DrillIntroVideo current="minefield" onVideoFinished={() => setVideoPlayed(true)} />;

    if (gameStarted)
        return <DrillMinefield retake={retake} cookie={Math.random() * 10000000} />;

    return (
        <>
            <main className="main">
                <section className="drill js-section section">
                    <div className="wrapper">
                        <div className="drill__header">
                            <div className="drill__icon">
                                <img src="/images/minefield-icon.svg" alt="" />
                            </div>
                            <h2 className="drill__title">Minefield</h2>
                            <div className="drill__list">
                                <div className="drill__item">
                                    <i className="svg-image-about-icon-3-sm" />
                                </div>
                                <div className="drill__item">
                                    <i className="svg-image-about-icon-5-sm" />
                                </div>
                            </div>
                        </div>
                        <div className="drill__subtitle">
                            Assess how well you prioritize. Stay focused on the most important
                            things while under stress.
                        </div>
                        <div className="drill__how-to">How to play</div>
                        <div className="drill__body">
                            Touch the mines to make them disappear. Misses will count against
                            your score. The drill ends when any mine explodes.
                        </div>
                        <div className="drill__example">
                            <div style={{ height: "220px" }}>
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                >
                                    <source src="/video/minefield.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                <Desktop />
            </main>
            <footer className="footer">
                <div className="wrapper">
                    <div className="footer-btns">
                        <button className="footer-btn btn" onClick={() => setGameStarted(true)}>
                            Get to Work
                        </button>
                    </div>
                </div>
            </footer>
        </>
    )
};

export default DrillMinefieldIntro;
