import React, { Component } from "react";
import { Button } from "../components/Icons";
import { drillCompleted } from "./Index";
import { Completed } from "../components/Completed";
import ReactionController from "../controllers/ReactionController";
import { BubbleTarget } from "../components/BubbleTarget";
import { withRouter } from "react-router";
import { DrillResult } from "../components/DrillResult";
import { DrillHint } from "../components/DrillHint";

const _xValues = shuffleArray([
	"10%",
	"10%",
	"10%",
	"10%",
	"10%",
	"10%",
	"10%",
	"10%",
	"10%",
	"10%",
	"70%",
	"70%",
	"70%",
	"70%",
	"70%",
	"70%",
	"70%",
	"70%",
	"70%",
	"70%",
]);

const defaultState = {
	rightButtonHeld: false,
	leftButtonHeld: false,
	tickTack: true,
	showTarget: false,
	result: null,
	showHint: false
};

class DrillReaction extends Component {
	constructor(props) {
		super(props);
		this.state = defaultState;
		this.debug = process.env.NODE_ENV === 'development';

		this.controller = new ReactionController(_xValues, d => this.setState(d));
	}

	componentDidMount() {
		document.getElementById('root').ontouchstart = function (e) { e.preventDefault(); };

		const leftHomebasePosition = getOffset("leftHome");
		const rightHomebasePosition = getOffset("rightHome");

		this.controller.start(leftHomebasePosition, rightHomebasePosition);

		this.interval = setInterval(() => {
			if (!this.controller.isPaused()) {
				if (!this.state.showTarget) {
					this.setState({ showTarget: this.controller.isTargetVisible() });
				}
			} else {
				this.controller.checkHint();
			}

			if (this.controller.isFinished()) {
				clearInterval(this.interval);
				document.getElementById('root').ontouchstart = function () { };

				const isRetake = this.props.retake;

				drillCompleted("reaction", isRetake, this.controller.transcript.stringify())
					.then(({ nextStep, result, todo }) => {
						this.setState({ nextStep, result, todo });
					});
			}
		}, 10);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		this.controller.stop();
	}

	leftBaseHeld = (e) => {
		e.stopPropagation();
		this.controller.leftBaseHeld(e.touches[0].clientX, e.touches[0].clientY);
	}

	leftBaseReleased = (e) => {
		e.stopPropagation();
		this.controller.leftBaseReleased((e.touches[0] || {}).clientX, (e.touches[0] || {}).clientY);
	}

	rightBaseHeld = (e) => {
		e.stopPropagation();
		this.controller.rightBaseHeld(e.touches[0].clientX, e.touches[0].clientY);
	}

	rightBaseReleased = (e) => {
		e.stopPropagation();
		this.controller.rightBaseReleased((e.touches[0] || {}).clientX, (e.touches[0] || {}).clientY);
	}


	handleTargetClick = (e) => {
		e.stopPropagation();
		const canProceed = this.controller.targetClicked(e.touches[0].clientX, e.touches[0].clientY);
		if (canProceed) {
			this.controller.nextQuestion();
		}
	};

	render() {
		if (this.state.result) {
			return <DrillResult drill="reaction" result={this.state.result} />
		}

		if (this.state.nextStep) {
			return <Completed current="reaction" next={this.state.nextStep} todo={this.state.todo} />;
		}

		const current = this.controller.getCurrent();

		return (
			<main className="main">
				<div className="game">
					<div className="game-field">
						{this.state.showTarget && current && <BubbleTarget
							x={current.x}
							rule={true}
							onShow={position => this.controller.targetShown(position.left, position.top)}
							onTouchStart={this.handleTargetClick}
						/>}

						{this.state.showHint && <DrillHint />}

						<div
							id="leftHome"
							style={{
								position: "absolute",
								left: '60px',
								bottom: '44px',
								width: '120px',
								height: '120px'
							}}
							onTouchStart={this.leftBaseHeld}
							onTouchEnd={this.leftBaseReleased}
						>
							<div
								style={{
									position: 'relative',
									height: '100px',
									width: '100px',
									left: '10px',
									top: '10px'
								}}
							>
								<Button active={this.state.leftButtonHeld} />
							</div>
						</div>

						<div
							id="rightHome"
							style={{
								position: "absolute",
								right: '60px',
								bottom: '44px',
								width: '120px',
								height: '120px',
							}}
							onTouchStart={this.rightBaseHeld}
							onTouchEnd={this.rightBaseReleased}
						>
							<div
								style={{
									position: 'relative',
									height: '100px',
									width: '100px',
									left: '10px',
									top: '10px'
								}}
							>
								<Button active={this.state.rightButtonHeld} />
							</div>
						</div>

					</div>
				</div>
			</main>
		);
	}
}

function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		const temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
}

function getOffset(id) {
	const el = document.getElementById(id);
	if (!el)
		return null;

	const rect = el.getBoundingClientRect();
	return {
		left: rect.left + window.scrollX,
		top: rect.top + window.scrollY,
	};
}

export default withRouter(DrillReaction);
