import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from '../api';
import { Desktop } from "../components/Desktop";
import { DrillsList } from "../components/DrillsList";
import { getNextStep } from './Index';
import { checkGetStateCalled, haveState, currentState } from '../globalState';

export const Drills = () => {
    const history = useHistory();

    useEffect(() => {
        checkGetStateCalled();
    }, []);

    const gotoNext = async () => {
        await api.assessment.startNew();
        const next = await getNextStep();
        history.replace(next);
    }

    if (haveState && !currentState.showProgressPage)
        gotoNext();

    const onLetsBegin = async () => {
        await api.assessment.startNew();
        const next = await getNextStep();
        history.push(next)
    };

    return (
        <>
            <main className="main">
                <DrillsList />
                <Desktop />
            </main>
            <footer className="footer">
                <div className="wrapper">
                    <div className="footer-btns">
                        <button className="footer-btn btn" onClick={onLetsBegin}>
                            Next
                        </button>
                    </div>
                </div>
            </footer>
        </>
    );
}
