import { useEffect, useState } from "react";
import { Desktop } from "../components/Desktop";
import { DrillIntroVideo } from "../components/DrillIntroVideo";
import DrillReaction from "./DrillReaction";
import { checkGetStateCalled, haveState, currentState } from '../globalState';

const DrillReactionIntro = ({ retake }) => {
    const [gameStarted, setGameStarted] = useState(false);
    const [videoPlayed, setVideoPlayed] = useState(false);

    useEffect(() => {
        checkGetStateCalled();
    }, []);;

    if ((!videoPlayed) && !(haveState && currentState.showVideos)) {
        // if state hasn't been received yet, err on the side of NOT playing the video
        setVideoPlayed(true);
    }

    if (!videoPlayed)
        return <DrillIntroVideo current="reaction" onVideoFinished={() => setVideoPlayed(true)} />;

    if (gameStarted)
        return <DrillReaction retake={retake} />;

    return (
        <>
            <main className="main">
                <section className="drill js-section section">
                    <div className="wrapper">
                        <div className="drill__header">
                            <div className="drill__icon">
                                <img src="/images/reaction-icon.png" alt="" />
                            </div>
                            <h2 className="drill__title">Reaction</h2>
                            <div className="drill__list">
                                <div className="drill__item">
                                    <i className="svg-image-about-icon-1-sm" />
                                </div>
                                <div className="drill__item">
                                    <i className="svg-image-about-icon-5-sm" />
                                </div>
                            </div>
                        </div>
                        <div className="drill__subtitle">
                            Assess your reaction time. How quickly can you see, process, and
                            act?
                        </div>
                        <div className="drill__how-to">How to play</div>
                        <div className="drill__body">
                            Start with you left and right index fingers on the home bases. Touch
                            the targets as quickly as possible as they appear.
                        </div>
                        <div className="drill__example">
                            <div style={{ height: "220px" }}>
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                >
                                    <source src="/video/reaction-time.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                <Desktop />
            </main>
            <footer className="footer">
                <div className="wrapper">
                    <div className="footer-btns">
                        <button className="footer-btn btn" onClick={() => setGameStarted(true)}>
                            Get to Work
                        </button>
                    </div>
                </div>
            </footer>
        </>
    )
};

export default DrillReactionIntro;
