import { useEffect, useState } from "react";
import { Desktop } from "../components/Desktop";
import { DrillIntroVideo } from "../components/DrillIntroVideo";
import DrillRuleChange from "./DrillRuleChange";
import { checkGetStateCalled, haveState, currentState } from '../globalState';

const DrillRuleChangeIntro = ({ retake }) => {
	const [gameStarted, setGameStarted] = useState(false);
	const [videoPlayed, setVideoPlayed] = useState(false);

	useEffect(() => {
		checkGetStateCalled();
	}, []);

	if ((!videoPlayed) && !(haveState && currentState.showVideos)) {
		// if state hasn't been received yet, err on the side of NOT playing the video
		setVideoPlayed(true);
	}

	if (!videoPlayed)
		return <DrillIntroVideo current="rule-change" onVideoFinished={() => setVideoPlayed(true)} />;

	if (gameStarted)
		return <DrillRuleChange retake={retake} />;

	return (
		<>
			<main className="main">
				<section className="drill js-section section">
					<div className="wrapper">
						<div className="drill__header">
							<div className="drill__icon">
								<img src="/images/rule-change-icon.svg" alt="" />
							</div>
							<h2 className="drill__title">RULE CHANGE</h2>
							<div className="drill__list">
								<div className="drill__item">
									<i className="svg-image-about-icon-4-sm" />
								</div>
								<div className="drill__item">
									<i className="svg-image-about-icon-1-sm" />
								</div>
							</div>
						</div>
						<div className="drill__subtitle">
							Assess your mental flexibility. Can you adapt to changing
							situations?
						</div>
						<div className="drill__how-to">How to play</div>
						<div className="drill__body">
							Start with your index finger on the home base. Quickly touch the image
							that matches the target based on the current rule (shape, color, or size).
						</div>
						<div className="drill__example">
							<div style={{ height: "220px" }}>
								<video
									autoPlay
									loop
									muted
									playsInline
								>
									<source src="/video/rule-change.mp4" type="video/mp4" />
								</video>
							</div>
						</div>
					</div>
				</section>
				<Desktop />
			</main>
			<footer className="footer">
				<div className="wrapper">
					<div className="footer-btns">
						<button className="footer-btn btn" onClick={() => setGameStarted(true)}>
							Get to Work
						</button>
					</div>
				</div>
			</footer>
		</>
	)
};

export default DrillRuleChangeIntro;
